(function($) {
	"use strict";

	/* Navigation Toggle
	   ========================================================================== */

	$(document).on("click", ".navigation-toggle", function(e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$(".navigation-collapse").toggleClass("navigation-open");
	});

	/* Sub Menu Toggle
	   ========================================================================== */
	$(document).on("click", ".sub-menu-toggle", function(e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$(this).parent().parent().children(".sub-menu").toggleClass("active");
	});

	/* Archive Dropdown
	 ========================================================================== */
	var archiveDropdown = $('#archive-dropdown');
	if(archiveDropdown) {
		// Push to category page on change event
		archiveDropdown.on('change', function() {
			if(this.options[this.selectedIndex].value != -1) {
				location.href = this.options[this.selectedIndex].value;
			}
		});

		// Set dropdown to current page is on archive page.
		var wrapper = $('#archive-dropdown').data('archive');
		if(wrapper) {
			var year = $('#archive-dropdown').data('year');
			var month = $('#archive-dropdown').data('month');

			if(month < 10) { month = '0' + month};
			var url = document.location.origin + '/' + year + '/' + month + '/';
			archiveDropdown.val(url);
		}
	}

	$(document).ready(function($) {

		/* Site Loaded
		   ========================================================================== */

		$(window).on("load", function () {
			$(".site").addClass("site-loaded");
		});

		/* Sticky Navigation
		   ========================================================================== */

		/*$(window).on("load scroll resize", function() {
			var stickyWrapper = $("#page");
			var	stickyElement = $("#masthead");
			var	stickyOffsetPostion = $("#masthead").innerHeight();
			var	stickyClass = "sticky";
			var	stickyScrolledClass = "sticky-scrolled";

			if ( window.innerWidth > 1023 && $(this).scrollTop() > stickyOffsetPostion ) {
				stickyWrapper.addClass(stickyClass);
				stickyElement.addClass(stickyClass);
			} else {
				stickyWrapper.removeClass(stickyClass);
				stickyElement.removeClass(stickyClass);
			}

			if ( window.innerWidth > 1023 && $(this).scrollTop() > stickyOffsetPostion + 1) {
				stickyElement.addClass(stickyScrolledClass);
			} else {
				stickyElement.removeClass(stickyScrolledClass);
			}
		});*/

		/* Search Toggle
		   ========================================================================== */

		$(document).on("click", "#masthead .search-toggle", function(e) {
			e.preventDefault();
			$("#masthead .search-toggle").toggleClass("active");
			$("#masthead .search-form").toggleClass("active");
			$("#masthead .search-field").focus();
		});

		$("body").click(function(e) {
			if ( $("#masthead .search-form").hasClass("active") ) {
				var target = $(e.target);
				if( !target.is("#masthead .search-field, #masthead .search-toggle") ) {
					$("#masthead .search-toggle").removeClass("active");
					$("#masthead .search-form").removeClass("active");
				}
			}
		});

		/* Telephone Links
		   ========================================================================== */

		$(".tel[data-phone], .tel .value[data-phone]").each(function() {
			var tel = $(this);
			var link = $("<a/>");

			link.html(tel.html());
			link.attr("href", "tel:" + tel.data("phone"));
			link.attr("class", tel.attr("class"));

			tel.replaceWith(link);
		});

		/* Tabset
		   ========================================================================== */

		var tabLinks = $("[data-tab]:not(.disabled)");
		var	tabContents = $("[data-tab-content]");
		var	tabLinkActive = ".tab-link.active";
		var	tabLinkActiveClass = "active";
		var	tabActiveClass = "active";

		$("[data-tabset]").each(function() {
			var wrapper = $(this);
			var group = wrapper.data("tab-group");
			var scope = "[data-tab-group=" + group + "]";
			var tabs = tabLinks.filter(scope);
			var content = tabContents.filter(scope);
			var init = wrapper.data("tab-init");

			// Init Tab

			$("[data-tab]" + scope).each(function(index, a) {
				if ($(a).attr("href") === window.location.hash) {
					// Do nothing
				}
				else {
					tabs.filter("[data-tab="+ init + "]").addClass(tabLinkActiveClass);
					content.filter("[data-tab-content=" + init + "]").addClass(tabActiveClass);
				}
			});

			// Tabs

			tabs.on("click", function(e) {
				var tab = $(this);
				var	activeTab = tab.data("tab");

				// Show active
				tabs.removeClass(tabLinkActiveClass).filter("[data-tab="+ activeTab +"]").addClass(tabLinkActiveClass);
				content.removeClass(tabActiveClass).filter("[data-tab-content=" + activeTab + "]").addClass(tabActiveClass);

				if ($(window).width() < 768) {
					$(document).scrollTop( $(this).offset().top );
				}
				e.preventDefault();
			});

			// Pevious Tab

			wrapper.on("click", "[data-tab-prev]" + scope, function(e) {
				for (var i = tabs.length - 1; i >= 0; i--) {
					var tab = tabs.eq(i);

					if (tab.is(tabLinkActive)) {
						tabs.eq(i - 1).trigger("click");

						break;
					}
				}
				e.preventDefault();
			});

			// Next Tab

			wrapper.on("click", "[data-tab-next]" + scope, function(e) {
				for (var i = 0, last = tabs.length; i < last; i++) {
					var tab = tabs.eq(i);

					if (tab.is(tabLinkActive)) {
						tabs.eq((i + 1) % last).trigger("click");

						break;
					}
				}
				e.preventDefault();
			});

			// Hash Tab

			$(window).on("hashchange load", function() {
				$("[data-tab]" + scope).each(function(index, a) {
					var tab = $(this);
					var	activeTab = tab.data("tab");

					if ($(a).attr("href") === window.location.hash && window.location.href) {
						tabs.removeClass(tabLinkActiveClass).filter("[data-tab="+ activeTab +"]").addClass(tabLinkActiveClass);
						content.removeClass(tabActiveClass).filter("[data-tab-content=" + activeTab + "]").addClass(tabActiveClass);
						$(document).scrollTop( $(this).offset().top );
					}
				});
			});
		});

		/* Accordion
		   ========================================================================== */

		var accordion = "[data-accordion]";
		var	accordionHeader = "[data-accordion-header]";
		var	accordionContent = "[data-accordion-content]";
		var	accordionActiveClass = "active";

		$(accordion)
		.find(accordionContent).hide().end()
		.find(accordionHeader +":first").addClass(accordionActiveClass).end()
		.find(accordionContent +":first").show().end()
		.on("click", accordionHeader, function(e) {
			var self = $(this);
			var	accordionBody = self.closest(accordion);

			if (!self.hasClass(accordionActiveClass)) {
				accordionBody
				.find(accordionHeader).removeClass(accordionActiveClass).end()
				.find(accordionContent).slideUp().end();

				self.addClass(accordionActiveClass)
				.next(accordionContent).slideDown(function() {
					if ($(window).width() < 768) {
						$(document).scrollTop( self.offset().top );
					}
				}).end();
			}
			e.preventDefault();
		});

		/* Video Overlay
		   ========================================================================== */

		$(".video-overlay[data-video-id]").one("click", function() {
			$(this).html("<iframe src=\"https://www.youtube.com/embed/" + $(this).data("video-id") +"?enablejsapi=1&autoplay=1&rel=0\" allowfullscreen></iframe>");
		});

		/* HTML5 Placeholder
		   ========================================================================== */

		$("input, textarea").placeholder();

		/* Slick Slider
		   ========================================================================== */

		$("[data-slick]").slick();

		/* Fancybox
		   ========================================================================== */

		// Gallery
		$(".gallery-thumbnail a").fancybox({
			margin: [44, 0],
			gutter : 32,
			caption : function() {
				return $(this).parent().parent().find("figcaption").html();
			}
		});

		/* Menu Disabled
		========================================================================== */
		$('.menu-item-disabled > a').click(function(e){
			e.preventDefault();
		});

		/* Btn Disabled
		========================================================================== */
		$('.btn-disabled > a').click(function(e){
			e.preventDefault();
		});

	}); // end.ready

}(jQuery));
